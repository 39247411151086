.side-nav {
  height: 100dvh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0;
  box-sizing: border-box;
  transition: all 0.2s;
  z-index: 9;
  scrollbar-width: thin;
  scrollbar-color: rgba(204, 204, 204, 0.499);
  // scrollbar-gutter: stable;

  ul {
    list-style-position: outside;
    padding: 0;
  }

  li {
    list-style-type: none;
    width: 80%;
    margin: 5px auto;
    cursor: pointer;
    box-sizing: border-box;

    svg {
      margin-right: 8px;
    }

    path {
      fill: #222222B2 !important;

      &.stroked {
        fill: none !important;
        stroke: #222222B2 !important;
      }
    }

    span {
      color: #222222B2 !important;
    }

    &:hover,
    &.selected {
      border-radius: 4px;
      background-color: #0898A0;

      path,
      circle {
        fill: #FFFFFF !important;

        &.stroked {
          fill: none !important;
          stroke: #FFFFFF !important;
        }
      }

      span {
        color: #ffffff !important;
      }
    }

    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
      cursor: pointer;
    }

    // span {
    //   // color: #FFF;
    // }
  }

  .s-openner {
    img {
      height: 40px;
      width: 40px;
      object-fit: contain !important;
      // transform: rotate(90deg);
      padding: 10px;
      cursor: pointer;

      &:hover {
        border-radius: 8px;
        background-color: #454547be;
      }
    }
  }

  &.show-nav {

    // width: 70px !important;
    // min-width: 70px !important;
    & * {
      visibility: visible;
    }
  }

  &.hide-nav {
    width: 0px;

    & * {
      visibility: hidden;
    }
  }

  .toggle-btn {
    visibility: visible !important;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -20px;
    top: -10px;
    background-color: #502C2B;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse 2s infinite;
    z-index: 9999;

    img {
      visibility: visible !important;
      width: 15px;
      height: 15px;
      object-fit: contain;
    }
  }
}

.toggle-help {
  font-size: 14px;
  width: 250px;
  height: 50px;
  border-radius: 8px;
  background: #fff !important;
  position: absolute;
  opacity: 1 !important;
  left: 110px;
  top: 0px;
  z-index: 9999999;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px 0px;

  &::before {
    background: #fff;
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: -10px;
    transform: rotate(45deg);
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}


@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes expand {
  from {
    width: 40px;
  }
  to {
    width: 170px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
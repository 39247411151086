.customer-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    border: 1px solid #9FA2B4;

    &:hover {
      background: #ece0d1 !important;
    }
  }
}

.create-customer {
  .input-wrapper {
    margin-bottom: 0;

    input {
      margin-bottom: 5px !important;
    }
  }

  form {
    button {
      margin-top: 15px !important;
    }
  }
}

.customer-card {
  width: 100%;
  max-width: 300px;
  height: 100px;
  padding: 4px;
  background-color: rgba(180, 13, 0, 0.1);
  position: relative;

  img {
    display: none;
  }

  &:hover {
    img {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .customer-wrapper {
    justify-content: center;
    align-items: center;
    padding: 10% 4%;
  }
}

@media only screen and (min-width: 600px) {
  .customer-wrapper {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2% 4%;
  }
}